import React, {useState, useEffect} from 'react';
import {AngelPost} from '../components'
import DOMPurify from 'dompurify';
import {getPosts} from '../api'
import ReactGA from 'react-ga4';
import { Link } from 'react-router-dom';
const TRACKING_ID = "G-6RX2L9KSTD"; // OUR_TRACKING_ID

export const Press = () => {
  const CATEGORY_ID = 12

    const [posts, setPosts] = useState(null)
    const [isLoading, setIsLoading] = useState(false)


    useEffect(() => {
      ReactGA.initialize([{ trackingId: TRACKING_ID }]);
      ReactGA.send({ hitType: "pageview", page: window.location.pathname, title: "Press" });
    }, []);

    const getPerformances = (page, category) => {
      setIsLoading(true)
      getPosts(page, category ? category : CATEGORY_ID, null, 100).then(result => {
        setIsLoading(false)
        setPosts(result.data.map(post => ({
          id: post.id,
          title: post.title.rendered,
          subtitle: post.acf.post_subtitle,
          content: post.excerpt.rendered,
          image: post._embedded['wp:featuredmedia'][0].source_url,
          slug: post.slug,
          historical_dates: post.acf.historical_dates,
          link_1: {url: post.acf.post_link_1 ? post.acf.post_link_1.link_url : null , label: post.acf.post_link_1 ? post.acf.post_link_1.link_text : null},
          link_2: {url: post.acf.post_link_2 ? post.acf.post_link_2.link_url : null , label: post.acf.post_link_2 ? post.acf.post_link_2.link_text : null},
          link_3: {url: post.acf.post_link_3 ? post.acf.post_link_3.link_url : null , label: post.acf.post_link_3 ? post.acf.post_link_3.link_text : null},
          link_4: {url: post.acf.post_link_4 ? post.acf.post_link_4.link_url : null , label: post.acf.post_link_4 ? post.acf.post_link_4.link_text : null},
          link_5: {url: post.acf.post_link_5 ? post.acf.post_link_5.link_url : null , label: post.acf.post_link_5 ? post.acf.post_link_5.link_text : null},
          gallery: post.gallery_images,
        })))
      })
    }


    useEffect(() => {
      getPerformances(1)
      window.scrollTo(0, 0);
    }, [])
  return (
    <div className={'max-w-screen-2xl mx-auto min-h-[100vh]'} id={'blog'}>
      <div className={' mt-20 sm:mt-12  flex justify-center w-full'}>
        <h1 className={'fade-inglowing-purple-text text-giek-purple max-w-screen-sm font-heading text-4xl sm:text-6xl mt-8 w-full text-giek-grey pb-2 text-center uppercase'}>Press</h1>
      </div>
      {isLoading && <p className={'w-full text-center font-extrabold animate-ping font-heading animate-pulse text-giek-purple text-4xl'}>Loading...</p>}

      {/* { posts && posts.map(post => <AngelPost post={post} hideLink />) } */}
      <div className={'hidden md:grid px-8 md:grid-cols-3 gap-8 max-w-screen-lg mx-auto'}>
        <div>
          {posts &&  
            posts.filter((p, i) => i % 3 == 0).map(p => {
              return (
                <a href={p.link_1.url} target="_blank">
                <div className={`fade-in w-full my-8 `}>
                  <img className={'w-full h-auto rounded-[50px] shadow shadow-sm shadow-giek-dark-violet'} src={p.image} />
                  <div className={'pb-8 font-secondary border-b border-giek-purple'}>
                  <p dangerouslySetInnerHTML={{__html: p.title}}className={'font-extrabold my-8 text-3xl glowing-purple-text font-primary text-dark-violet'}></p>
                  <p dangerouslySetInnerHTML={{__html: p.content}} className={'font-extrabold text-giek-dark-violet'}></p>
                  {p.link_1.url && <div className={'flex justify-end'}><a onClick={(e) => e.stopPropagation()} className="inline-block font-secondary text-lg bg-giek-purple text-white rounded-xl px-2 py-1 top-4 relative uppercase" href={p.link_1.url} >{p.link_1.label}</a></div>}
                  {p.link_2.url && <div className={'flex justify-end'}><a onClick={(e) => e.stopPropagation()} className="inline-block font-secondary text-lg bg-giek-purple text-white rounded-xl px-2 py-1 top-4 relative uppercase" href={p.link_2.url} >{p.link_2.label}</a></div>}
                  {p.link_3.url && <div className={'flex justify-end'}><a onClick={(e) => e.stopPropagation()} className="inline-block font-secondary text-lg bg-giek-purple text-white rounded-xl px-2 py-1 top-4 relative uppercase" href={p.link_3.url} >{p.link_3.label}</a></div>}
                  {p.link_4.url && <div className={'flex justify-end'}><a onClick={(e) => e.stopPropagation()} className="inline-block font-secondary text-lg bg-giek-purple text-white rounded-xl px-2 py-1 top-4 relative uppercase" href={p.link_4.url} >{p.link_4.label}</a></div>}
                  {p.link_5.url && <div className={'flex justify-end'}><a onClick={(e) => e.stopPropagation()} className="inline-block font-secondary text-lg bg-giek-purple text-white rounded-xl px-2 py-1 top-4 relative uppercase" href={p.link_5.url} >{p.link_5.label}</a></div>}
                  </div>
                </div>
                
                </a>
              )
          })}
        </div>
        <div>
          {posts && 
            posts.filter((p, i) => i % 3 == 2).map(p => {
              return (
                <a href={p.link_1.url} target="_blank">
                <div className={'fade-in w-full my-8'}>
                  <img className={'w-full h-auto rounded-[50px] shadow shadow-sm shadow-giek-dark-violet'} src={p.image} />
                  <div className={'pb-8 font-secondary border-b border-giek-purple'}>
                  <p dangerouslySetInnerHTML={{__html: p.title}}className={'font-extrabold my-8 text-3xl glowing-purple-text font-primary text-dark-violet'}></p>
                  <p dangerouslySetInnerHTML={{__html: p.content}} className={'font-extrabold text-giek-dark-violet'}></p>
                  {p.link_1.url && <div className={'flex justify-end'}><a onClick={(e) => e.stopPropagation()} className="inline-block font-secondary text-lg bg-giek-purple text-white rounded-xl px-2 py-1 top-4 relative uppercase" href={p.link_1.url} >{p.link_1.label}</a></div>}
                  {p.link_2.url && <div className={'flex justify-end'}><a onClick={(e) => e.stopPropagation()} className="inline-block font-secondary text-lg bg-giek-purple text-white rounded-xl px-2 py-1 top-4 relative uppercase" href={p.link_2.url} >{p.link_2.label}</a></div>}
                  {p.link_3.url && <div className={'flex justify-end'}><a onClick={(e) => e.stopPropagation()} className="inline-block font-secondary text-lg bg-giek-purple text-white rounded-xl px-2 py-1 top-4 relative uppercase" href={p.link_3.url} >{p.link_3.label}</a></div>}
                  {p.link_4.url && <div className={'flex justify-end'}><a onClick={(e) => e.stopPropagation()} className="inline-block font-secondary text-lg bg-giek-purple text-white rounded-xl px-2 py-1 top-4 relative uppercase" href={p.link_4.url} >{p.link_4.label}</a></div>}
                  {p.link_5.url && <div className={'flex justify-end'}><a onClick={(e) => e.stopPropagation()} className="inline-block font-secondary text-lg bg-giek-purple text-white rounded-xl px-2 py-1 top-4 relative uppercase" href={p.link_5.url} >{p.link_5.label}</a></div>}
                  </div>
                </div>
                </a>
              )
          })}
        </div>
        <div>
          {posts && 
            posts.filter((p, i) => i % 3 == 1).map(p => {
              return (
                <a href={p.link_1.url} target="_blank">
                <div className={'fade-in w-full my-8'}>
                  <img className={'w-full h-auto rounded-[50px] shadow shadow-sm shadow-giek-dark-violet'} src={p.image} />
                  <div className={'pb-8 font-secondary border-b border-giek-purple'}>
                  <p dangerouslySetInnerHTML={{__html: p.title}}className={'font-extrabold my-8 text-3xl glowing-purple-text font-primary text-dark-violet'}></p>
                  <p dangerouslySetInnerHTML={{__html: p.content}} className={'font-extrabold text-giek-dark-violet'}></p>
                  {p.link_1.url && <div className={'flex justify-end'}><a onClick={(e) => e.stopPropagation()} className="inline-block font-secondary text-lg bg-giek-purple text-white rounded-xl px-2 py-1 top-4 relative uppercase" href={p.link_1.url} >{p.link_1.label}</a></div>}
                  {p.link_2.url && <div className={'flex justify-end'}><a onClick={(e) => e.stopPropagation()} className="inline-block font-secondary text-lg bg-giek-purple text-white rounded-xl px-2 py-1 top-4 relative uppercase" href={p.link_2.url} >{p.link_2.label}</a></div>}
                  {p.link_3.url && <div className={'flex justify-end'}><a onClick={(e) => e.stopPropagation()} className="inline-block font-secondary text-lg bg-giek-purple text-white rounded-xl px-2 py-1 top-4 relative uppercase" href={p.link_3.url} >{p.link_3.label}</a></div>}
                  {p.link_4.url && <div className={'flex justify-end'}><a onClick={(e) => e.stopPropagation()} className="inline-block font-secondary text-lg bg-giek-purple text-white rounded-xl px-2 py-1 top-4 relative uppercase" href={p.link_4.url} >{p.link_4.label}</a></div>}
                  {p.link_5.url && <div className={'flex justify-end'}><a onClick={(e) => e.stopPropagation()} className="inline-block font-secondary text-lg bg-giek-purple text-white rounded-xl px-2 py-1 top-4 relative uppercase" href={p.link_5.url} >{p.link_5.label}</a></div>}
                  </div>
                </div>
                </a>
              )
          })}
        </div>
      </div>
      <div className={'block md:hidden px-8 gap-8 max-w-screen-lg mx-auto'}>
        <div>
          {posts &&  
            posts.map(p => {
              return (
                <a href={p.link_1.url} target="_blank">
                <div className={`fade-in w-full my-8 `}>
                  <img className={'w-full h-auto rounded-[50px] shadow shadow-sm shadow-giek-dark-violet'} src={p.image} />
                  <div className={'pb-8 font-secondary border-b border-giek-purple'}>
                  <p dangerouslySetInnerHTML={{__html: p.title}} className={'font-extrabold my-8 text-3xl glowing-purple-text font-primary text-dark-violet'}></p>
                  <p dangerouslySetInnerHTML={{__html: p.content}} className={'font-extrabold text-giek-dark-violet'}></p>
                  {p.link_1.url && <div className={'flex justify-end'}><a onClick={(e) => e.stopPropagation()} className="inline-block font-secondary text-lg bg-giek-purple text-white rounded-xl px-2 py-1 top-4 relative uppercase" href={p.link_1.url} >{p.link_1.label}</a></div>}
                  {p.link_2.url && <div className={'flex justify-end'}><a onClick={(e) => e.stopPropagation()} className="inline-block font-secondary text-lg bg-giek-purple text-white rounded-xl px-2 py-1 top-4 relative uppercase" href={p.link_2.url} >{p.link_2.label}</a></div>}
                  {p.link_3.url && <div className={'flex justify-end'}><a onClick={(e) => e.stopPropagation()} className="inline-block font-secondary text-lg bg-giek-purple text-white rounded-xl px-2 py-1 top-4 relative uppercase" href={p.link_3.url} >{p.link_3.label}</a></div>}
                  {p.link_4.url && <div className={'flex justify-end'}><a onClick={(e) => e.stopPropagation()} className="inline-block font-secondary text-lg bg-giek-purple text-white rounded-xl px-2 py-1 top-4 relative uppercase" href={p.link_4.url} >{p.link_4.label}</a></div>}
                  {p.link_5.url && <div className={'flex justify-end'}><a onClick={(e) => e.stopPropagation()} className="inline-block font-secondary text-lg bg-giek-purple text-white rounded-xl px-2 py-1 top-4 relative uppercase" href={p.link_5.url} >{p.link_5.label}</a></div>}
                  </div>
                </div>
                </a>
              )
          })}
        </div>
      </div>
    </div>
  )
}

export default Press;
